import 'core-js/stable';
import 'regenerator-runtime/runtime';
import $ from 'jquery';
import stickyFooter from 'sticky-footer';
import SmoothScroll from 'smooth-scroll';
import headroomJS from 'headroom.js';
import Tabby from 'Tabby';
import LazyLoad from "vanilla-lazyload";
import slickCarousel from 'slick-carousel';
import fancybox from '@fancyapps/fancybox';
import addBrowserClass from './modules/browser';
import fontSetting from './modules/font';
import MicroModal from 'micromodal';
import mmenu from './modules/jquery.mmenu';
import yubinbango from './modules/yubinbango';
import mixitup from 'mixitup';

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// Lazy JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
if ($('.lazy').length) {
    const myLazyLoad = new LazyLoad({
        container: document.querySelector(".lazy")
    });
} else {}

// -------------------------------------------------------------------
// MicroModal
// -------------------------------------------------------------------
let player; // このスコープでplayer変数を宣言

// 動画を一時停止する関数を定義
const pauseVideo = () => {
    if (player) {
        player.pause();
    }
};

// MicroModalの設定
MicroModal.init({
    onShow: function(modal) {
        var iframe = modal.querySelector('iframe');

        // 前回のplayerがあれば一時停止（複数の動画を再生しないように）
        if (player) {
            player.pause();
        }

        player = new Vimeo.Player(iframe);
    },
    closeTrigger: 'data-micromodal-headerClose',
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: true,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    onClose: pauseVideo  // モーダルが閉じられたときにpauseVideo関数を呼び出します
});
// -----------------------------------------------------
// -------------------------------------------------------------------
// tabby JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
// var tabs = new Tabby('[data-tabs]');
if ($('[data-tabs]').length) {
    const tabs = new Tabby('[data-tabs]');
} else {}

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
stickyFooter.init({
    selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
    selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
    callback: function() {}, // Runs after the footer is stuck
});

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// slick-carousel JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
$('.p-about7steps-container').slick({
    centerMode: true, //センターモード
    centerPadding: '0px', //前後のパディング
    autoplay: false, //オートプレイ
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    // normal options...
    infinite: false,
    prevArrow: '<div class="p-about7steps__Prev"><i class="far fa-long-arrow-left"></i></div>',
    nextArrow: '<div class="p-about7steps__Next"><i class="far fa-long-arrow-right"></i></div>',
    speed: 300,
    dots: false,
});
$('.js-bizCaseItems').slick({
    centerMode: true, //センターモード
    centerPadding: '0px', //前後のパディング
    autoplay: false, //オートプレイ
    draggable: true,
    // normal options...
    prevArrow: '<div class="c-carousel-prev"><i class="far fa-long-arrow-left"></i></div>',
    nextArrow: '<div class="c-carousel-next"><i class="far fa-long-arrow-right"></i></div>',
    speed: 300,
    infinite: true,
    dotsClass: 'c-carouselDots',
    variableWidth: true,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1240,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
    }, {
        breakpoint: 810,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    }, {
        breakpoint: 475,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }]
});
// -------------------------------------------------------------------
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]:not([href*="#offcanvasMenu"])', {
    speed: 500,
    speedAsDuration: true
});
// const scroll = new SmoothScroll('a[href*="#"]'+'a:not([href *= "#offcanvasMenu"])');

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// headroom JS
// -------------------------------------------------------------------
const header = document.querySelector('.l-header');
let headroom = new headroomJS(header, {
    //上から120pxより下の領域で10px以上スクロールするとイベントが発動する
    "offset": 80,
    "tolerance": 0,
    "classes": {
        "initial": "initial"
    },
});
headroom.init();

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// mixitup JS
// -------------------------------------------------------------------
if ($('#js-container').length) {
    mixitup('#js-container', {
        load: {
            sort: 'order:asc'
        },
        animation: {
            // effects: 'fade rotateZ(-180deg)',
            duration: 700
        },
        classNames: {
            block: 'js-controls',
            elementFilter: 'filter-btn',
            elementSort: 'sort-btn'
        },
        selectors: {
            target: '.c-bizcaseItem'
        }
    });
} else {}

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// jQuery
// -------------------------------------------------------------------
// -------------------------------------------------------------------
$(function() {

    setInterval(function() {
        var now = new Date();
        var y = now.getFullYear();
        var m = now.getMonth() + 1;
        $('.js-presentDate').text(y);
    }, 0);

    $('.js-btnCheck').on('click', function() {
        $('.js-btnActive').slideToggle("fast");
    });


    $('.p-contactContents__Innr-body.l-form .wpcf7-form').addClass("h-adr");

    $("#offcanvasMenu").mmenu({
        "extensions": [
            "pagedim-black",
            "position-right"
        ],
        "counters": false,
        "iconPanels": true,
        "pageScroll": true,
        "navbar": {
            title: "<h2 class=\"c-menu-logo\"><img src=\"/assets/img/logo_white.svg\" alt=\"\" /></h2>"
        },
        "navbars": [{
            "position": "top"
        }]
    }, {
        // configuration
        offCanvas: {
            pageNodetype: "section"
        }
    });
    // カテゴリーページへのリンクを書き換え
    $('.is-jqAttach').each(function(i, elem) {
        var str = $(elem).attr('href');
        str = str.replace('/category/', '/news/category/');
        $(elem).attr('href', str);
    });
    // カテゴリーページへのリンクを書き換え
    $('.is-jqAttachParent a').each(function(i, elem) {
        var str = $(elem).attr('href');
        str = str.replace('/category/', '/news/category/');
        $(elem).attr('href', str);
    });
    // プルダウン変更時に遷移
    $('select[name=selectorArchives]').change(function() {
        if ($(this).val() != '') {
            window.location.href = $(this).val();
        }
    });
    $(".js-7Steps-trigger").on('click', function() {
        $.fancybox.open({
            src: '#about7steps',
            type: 'inline',
            // キーボード操作を有効にする
            keyboard: true,
            // 画面の端にナビゲーション矢印を表示する
            arrows: true,
            // 情報バーの表示 (上部に1/10のような数字と矢印が表示されます)
            infobar: true,
            // ツールバーを表示 (上部にボタンが表示)
            toolbar: true,
            // コンテンツの右上隅のボタン
            smallBtn: false,
            showNavArrows: false,
        });
        $('.p-about7steps-container').slick('setPosition');
    });
    $(".js-book-trigger").on('click', function() {
        $.fancybox.open({
            src: '#book',
            type: 'inline',
            // キーボード操作を有効にする
            keyboard: true,
            // 画面の端にナビゲーション矢印を表示する
            arrows: true,
            // 情報バーの表示 (上部に1/10のような数字と矢印が表示されます)
            infobar: true,
            // ツールバーを表示 (上部にボタンが表示)
            toolbar: true,
            // コンテンツの右上隅のボタン
            smallBtn: false,
            showNavArrows: false,
        });
        $('.p-about7steps-container').slick('setPosition');
    });
    $(".js-contentsCheck").click(function() {
        $(".js-contentsBody").toggle();
    });
    // アコーディオン
    $(".js-trigger").on("click", function() {
        $(this).next(".js-hiddenContents").slideToggle("fast");
        $("html,body").animate();
        if ($(this).hasClass("is-active")) { // クリックされた要素がclickedクラスだったら
            $(this).removeClass("is-active");
        } else {
            $(this).addClass("is-active");
        }
    });
    if ($('html.is-usp').length) {
        $('.l-nav__Innr-usp a').addClass("is-active");
    }
    if ($('html.is-bizcases').length) {
        $('.l-nav__Innr-bizCases a').addClass("is-active");
    }
    if ($('html.is-seminar').length) {
        $('.l-nav__Innr-seminar a').addClass("is-active");
    }
    if ($('html.is-services').length) {
        $('.l-nav__Innr-services a').addClass("is-active");
    }
    if ($('html.is-consultants').length) {
        $('.l-nav__Innr-consultants a').addClass("is-active");
    }
    if ($('html.is-aboutUs').length) {
        $('.l-nav__Innr-aboutUs a').addClass("is-active");
    }
    if ($('html.is-news').length) {
        $('.l-nav__Innr-news a').addClass("is-active");
    }
    if ($('html.is-selfDev').length) {
        $('.l-nav__Innr-selfDev a').addClass("is-active");
    }
    //二重送信抑制
    let submitBtn = $("input[type='submit'].wpcf7-submit");
    submitBtn.click(function() {
            $(this).css('pointer-events', 'none');
            $(this).css('opacity', '0.5');
        })
        // 入力項目にエラーがあったらボタン復活
    document.addEventListener('wpcf7invalid', function() {
        submitBtn.css('pointer-events', 'auto');
        submitBtn.css('opacity', '1');
    }, false);
});
